<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 당근농장 수정"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <NewsLetterTab v-if="bbs.idx" :idx="bbs.idx" :tidx="4"></NewsLetterTab>
                <div>
                  <ul>
                    <li>- 캐럿HUB 사이트의 Program Report를 관리할 수 있습니다.</li>
                    <li>- 첨부파일은 각각 최대 3MB까지 업로드 가능합니다.</li>
                    <li>- 최근 게시물로부터 상위 6개의 항목이 순서대로 노출됩니다.</li>
                    <li>* CARROT HUB : <a href="https://carrotglobal.com">https://carrotglobal.com</a></li>
                  </ul>
                </div>
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row" >
                        <colgroup>
                            <col width="180">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody v-for="(irow, i) in bbs.indexs" :key="i">
                            <tr>
                                <th rowspan="7">
                                    Program<br>Report
                                    <br>{{ i+1 }}
                                    <div class="mt-10">
                                        <button v-if="i==0" @click="bbs.addIndexs()" class="btn-default dp-inblock h-30px">추가</button>
                                        <label v-else-if="bbs.indexs[i].is_update" class="dp-inblock h-30px"><input type="checkbox" v-model="bbs.indexs[i].allDchk"> 삭제</label>
                                        <button v-else @click="bbs.delIndexs(i)" class="btn-default dp-inblock h-30px">삭제</button>
                                    </div>
                                </th>
                                <th><span class="txt-red">*</span>사보명</th>
                                <td>
                                    <input type="text" v-model="irow.magazine_name" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>회사/부서명</th>
                                <td>
                                    <input type="text" v-model="irow.company" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>프로그램명</th>
                                <td>
                                    <input type="text" v-model="irow.program" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>본문요약</th>
                                <td>
                                    <input type="text" v-model="irow.summary" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>썸네일</th>
                                <td>
                                    <a v-if="irow.file1_name" :href="irow.file1_url" class="btn-view" target="_blank">{{ irow.file1_name }}</a>
                                    <!-- <label v-if="irow.file1_name" class="ml-10"><input type="checkbox" v-model="irow.dchk1"> 삭제</label> -->
                                    <label class="ml-10" style="display:none"><input type="checkbox" v-model="irow.dchk1" > 삭제</label>
                                    <button v-if="irow.file1_name" @click="bbs.delFileIndexs(i,'1')" class="btn-default dp-inblock h-30px ml-10">삭제</button>
                                    <CarrotFileUploader ref="iFile1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>PC 이미지</th>
                                <td>
                                    <a v-if="irow.file2_name" :href="irow.file1_url" class="btn-view" target="_blank">{{ irow.file2_name }}</a>
                                    <!-- <label v-if="irow.file2_name" class="ml-10"><input type="checkbox" v-model="irow.dchk2"> 삭제</label> -->
                                    <label class="ml-10" style="display:none"><input type="checkbox" v-model="irow.dchk2"> 삭제</label>
                                    <button v-if="irow.file2_name" @click="bbs.delFileIndexs(i,'2')" class="btn-default dp-inblock h-30px ml-10">삭제</button>
                                    <CarrotFileUploader ref="iFile2"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>모바일 이미지</th>
                                <td>
                                    <a v-if="irow.file3_name" :href="irow.file1_url" class="btn-view" target="_blank">{{ irow.file3_name }}</a>
                                    <!-- <label v-if="irow.file3_name" class="ml-10"><input type="checkbox" v-model="irow.dchk3"> 삭제</label> -->
                                    <label class="ml-10" style="display:none"><input type="checkbox" v-model="irow.dchk3"> 삭제</label>
                                    <button v-if="irow.file3_name" @click="bbs.delFileIndexs(i,'3')" class="btn-default dp-inblock h-30px ml-10">삭제</button>
                                    <CarrotFileUploader ref="iFile3"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">저장</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import NewsLetterTab from '@/components/common/NewsLetterTab.vue'

export default {
    layout:"ResourceManagement",
    components: {
      NewsLetterTab
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();
        
        const iFile1 = ref([]);
        const iFile2 = ref([]);
        const iFile3 = ref([]);
        const bbs = reactive({
            index_length : 6,
            indexs:[],

            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },
            addIndexs : () => {
                if(bbs.indexs.length < bbs.index_length) {
                    bbs.indexs.push({
                        magazine_name : '',
                        company : '',
                        program : '',
                        summary : '',
                        file1_name : '',
                        file2_name : '',
                        file3_name : '',
                    });
                } else {
                    toast.error(`최대 추가 갯수는 ${bbs.index_length}개 입니다. 추가를 원하시면 문의 주세요`);
                }
            },
            delFileIndexs : (i, v) => {
                bbs.indexs[i][`dchk${v}`] = true;
                bbs.indexs[i][`file${v}_name`] = "";
            },
            delIndexs : (i) => {
                bbs.indexs.splice(i, 1);
            },
            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/resourceManagement/reportView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        if(res.data.idx_list){
                            for( let [i,idx_list] of res.data.idx_list.entries()) {
                                bbs.indexs[i] = {
                                    is_update: true, 
                                    allDchk : false, //전체 삭제
                                    magazine_name: idx_list.magazine_name,
                                    company: idx_list.company,
                                    program: idx_list.program,
                                    summary: idx_list.summary,

                                    file1_name: idx_list.file1_name,
                                    file1_url: idx_list.file1_url,
                                    dchk1: false,
                                    
                                    file2_name: idx_list.file2_name,
                                    file2_url: idx_list.file2_url,
                                    dchk2: false,

                                    file3_name: idx_list.file3_name,
                                    file3_url: idx_list.file3_url,
                                    dchk3: false,
                                }
                            }
                        }else{
                            bbs.addIndexs();
                        }
                        
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            onSave: () => {
                for(var i in bbs.indexs) {
                    if(!bbs.indexs[i].magazine_name) {
                        toast.error("사보명을 입력하세요.");
                        return;
                    }

                    if(!bbs.indexs[i].company) {
                        toast.error("회사/부서명을 입력하세요.");
                        return;
                    }

                    if(!bbs.indexs[i].program) {
                        toast.error("프로그램명을 입력하세요.");
                        return;
                    }

                    if(!bbs.indexs[i].summary) {
                        toast.error("본문요약을 입력하세요.");
                        return;
                    }
                }
                var formData = new FormData();
                formData.append("idx", bbs.idx);
                
                for(i in bbs.indexs) {
                    formData.append("magazine_name[]", bbs.indexs[i].magazine_name);
                    formData.append("company[]", bbs.indexs[i].company);
                    formData.append("program[]", bbs.indexs[i].program);
                    formData.append("summary[]", bbs.indexs[i].summary);
                    formData.append("allDchk[]", (bbs.indexs[i].allDchk === true) ? 'Y' : 'N');
                    formData.append("dchk[${i}][]", (bbs.indexs[i].dchk1 === true) ? 'Y' : 'N');
                    formData.append("dchk[${i}][]", (bbs.indexs[i].dchk2 === true)? 'Y' : 'N');
                    formData.append("dchk[${i}][]", (bbs.indexs[i].dchk3 === true) ? 'Y' : 'N');
                    
                }
                const arr_filetxt = {
                    1: "썸네일",
                    2: "PC 이미지",
                    3: "모바일 이미지"
                };

                //파일
                for(i=1; i <= 3; i++) {
                    let iFile = eval(new String(`iFile${i}`).toString());
                    for(let j=0; j <= bbs.indexs.length-1; j++) {
                        if(iFile.value[j]?.file.filedata) {
                            formData.append(`index_files${i}_${j+1}`, iFile.value[j].file.filedata);
                        }else{
                            if(bbs.indexs[j][`dchk${i}`] === true || !bbs.indexs[j][`file${i}_url`]){
                                const filetxt = arr_filetxt[i];
                                toast.error(filetxt+" 등록하세요.");
                                return;
                            }
                        }
                    }
                }
                
                if(!confirm("저장하시겠습니까?")) return;

                axios.post('/rest/resourceManagement/reportForm', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"ResourceManagement-NewsLetterManagementView_tab4-idx",
                            params : { idx:bbs.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        

        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.back(-1);
            }
            bbs.getInfo();

        });

        return {bbs,iFile1,iFile2,iFile3};
    }
}
</script>

<style lang="scss" scoped>
</style>